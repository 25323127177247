(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreManagementDetailController', StoreManagementDetailController)
        .directive('onErrorSrc', function () {
            return {
                link: function (scope, element, attrs) {
                    element.bind('error', function () {
                        if (attrs.src != attrs.onErrorSrc) {
                            attrs.$set('src', attrs.onErrorSrc);
                        }
                    });
                }
            }
        });

    StoreManagementDetailController.$inject = ['$scope', '$rootScope', '$localStorage', 'FlyersService', '$state', 
        '$timeout', 'uiGridConstants', 'uiGridBuilder', '$translate', '$stateParams', 'StoreService', '$uibModal', '$q', 
        'CategoryService', 'UserService', 'UserProfileService', 'toastr', 'RemoveModalFactory', 'TeamService', 'ModalFactory'];

    function StoreManagementDetailController($scope, $rootScope, $localStorage, FlyersService, $state, 
        $timeout, uiGridConstants, uiGridBuilder, $translate, $stateParams, StoreService, $uibModal, $q, 
        CategoryService, UserService, UserProfileService, toastr, RemoveModalFactory, TeamService, ModalFactory) {
        var vm = this;

        vm.mainMap = {
            center: {
                latitude: 0,
                longitude: 0
            },
            zoom: 1
        };
        vm.mainMap.controls = {};
        vm.mainMap.bounds = {};
        vm.mainMap.markers = [];
        vm.options= {
            fullscreenControl: false
        };
        vm.mainMap.window = {
            model: {},
            show: false,
            options: {
                pixelOffset: {
                    width: -1,
                    height: -20
                }
            },
            closeClick: function() {
                this.show = false;
            }
        };
        vm.usersTable = false;
        vm.profilesTable = false;
        vm.mainMap.markerEvents = {
            click: function(marker, eventName, model, args) {
                $uibModal.open({
                    templateUrl: 'app/admin/store-management/store-management-newsalespoint.html',
                    controller: 'AddSalesPointManagementController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    scope: $scope,
                    size: 'lg',
                    resolve: {
                        pointlocation: function() {
                            return angular.copy(model);
                        }
                    }
                }).result.then(function(data) {
                    if (data) {
                        // $scope.parameter = data;
                    }
                });
                vm.mainMap.window.model = model;
                vm.mainMap.window.show = true;
            }
        };

        vm.tabType = "Users";
        vm.isLoading = true;
        vm.showMap = false;
        vm.showTable = false;
        vm.defaultSearchIndex = 0;
        vm.tableDivisor = 5;
        vm.columnDefs = getColumnDefsUsers();
        vm.filterFields = {};

        loadAll();

        const ICON_ACTIVE = 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/pointactive.png';

        function loadAll() {
            StoreService.getStore($stateParams.id).then(function(response) {
                vm.store = response.data;
                vm.store.image = vm.loadStoreImage(vm.store.storeLogo);
                vm.imagePath = "/admin/api/" + vm.store.id + "/store";
                getCategoryName();
                loadNumberOfFlyers();
                convertToMarkers(vm.store.points);
                vm.gridTableOptions = getGridOptions();
                vm.usersTable = true;
                vm.isLoading = false;

                if ($stateParams.fromStep) {
                    vm.getEditStorePage(true)
                }
            });
        }

        function convertToMarkers(points) {
            console.log('convertToMarkers>>> ', points);
            vm.store.points.forEach(function(point) {
            
                vm.mainMap.markers.push({
                    pointlocation: {
                        name: point.name,
                        country: point.country,
                        state: point.state,
                        neighborhood: point.neighborhood,
                        number: point.number,
                        address: point.address,
                        city: point.city,
                        id: point.id,
                        mainPoint: point.mainPoint,
                        postalCode: point.postalCode,
                        contactPhone: point.contactPhone,
                        storeId: vm.store.id
                    },
                    latitude: point.coordinate.latitude,
                    longitude: point.coordinate.longitude,
                    id: point.id,
                    options: {
                        icon: ICON_ACTIVE
                    }
                });
            });
            $timeout(function() {
                vm.showMap = true;
            }, 2000);
        }

        vm.reloadUsers = function() {
            $scope.search();
        }

        $scope.search = function() {
            if (vm.tabType === "Users") {
                vm.changeTableToUsers();
            } else if (vm.tabType === "Profiles") {
                vm.changeTableToProfile();
            } else if (vm.tabType === "Teams") {
                vm.changeTableToTeam();
            }
        }

        $scope.$on('salesPointUpdated', function(event, salesPoint) {
            updateSalesPoint(salesPoint);

        });

        $scope.$on('salesPointCreated', function(event, salesPoint) {
            createSalesPoint(salesPoint);
        });

        $scope.$on('salesPointRemoved', function(event, salesPoint) {
            if (vm.mainMap.markers.length > 1) {
                RemoveModalFactory.deleteItem(1).then(function() {
                    StoreService.deleteSalesPoint(salesPoint.id).then(function() {
                        vm.mainMap.markers.splice(vm.mainMap.markers.indexOf(salesPoint), 1);
                        vm.mainMap.window.show = false;
                    });
                });
            } else {
                toastr.error($translate.instant('store-management.messages.remove.minSalesPoints'));
            }
        });


        $scope.$on('userUpdated', function(event, data) {
            $scope.search();
        });

        vm.firstZoom = true;

        $scope.$watch('vm.mainMap.zoom', function(newVal) {
            if (newVal > 16 && vm.firstZoom) {
                vm.mainMap.zoom = 16;
                vm.firstZoom = false;
            }
        });

        vm.loadStoreImage = function(image) {
          var random = new Date().getTime();
          return (image + '?r=' + random);
        }

        vm.addSalesPoint = function() {
            $uibModal.open({
                templateUrl: 'app/admin/store-management/store-management-newsalespoint.html',
                controller: 'AddSalesPointManagementController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    pointlocation: function() {
                        return $scope.parameter;
                    },
                    pointparent: function() {
                        return $scope.$parent.vm;
                    }
                }
            });
        }

        function findAndReplaceUpdatedSalePoint(sp){
            var found = false;

            for (var i = 0; i < vm.mainMap.markers.length; i++){
                if (vm.mainMap.markers[i].id == sp.id){
                    vm.mainMap.markers[i] = sp;
                    found = true;
                }
                if (found){
                    return;
                }
            }

            vm.mainMap.markers.push(sp);
        }

        function createSalesPoint(point) {
            var newPoint = point.pointlocation;
            newPoint.storeId = vm.store.id;
            newPoint.coordinate = {
                latitude: "",
                longitude: ""
            };
            newPoint.coordinate.latitude = point.latitude;
            newPoint.coordinate.longitude = point.longitude;
            StoreService.createSalesPoint(newPoint).then(function(data) {
                vm.mainMap.window.show = false;
                point.options = {
                    icon: ICON_ACTIVE
                };
                point.pointlocation.id = data.id;
                point.id = data.id;
                vm.mainMap.markers.push(point);
                toastr.success($translate.instant('store-management.messages.salespoint.create.success'));
            }, function(data) {
                toastr.success($translate.instant('store-management.messages.salespoint.create.error'));
            });
        }

        function updateSalesPoint(point) {
            var newPoint = point.pointlocation;
            newPoint.storeId = vm.store.id;
            newPoint.coordinate = {
                latitude: "",
                longitude: ""
            };
            newPoint.coordinate.latitude = point.latitude;
            newPoint.coordinate.longitude = point.longitude;
            StoreService.updateSalesPoint(newPoint).then(function(data, error) {
                vm.mainMap.window.show = false;
                findAndReplaceUpdatedSalePoint(point);
                toastr.success($translate.instant('store-management.messages.salespoint.update.success'));
            }, function(data) {
                toastr.error($translate.instant('store-management.messages.salespoint.update.error'));
            });
        }

        function updateStore() {
            vm.store.points = [];
            var point = {};
            vm.mainMap.markers.forEach(function(marker) {
                point = marker.pointlocation;
                point.coordinate = {
                    latitude: "",
                    longitude: ""
                };
                point.coordinate.latitude = marker.latitude;
                point.coordinate.longitude = marker.longitude;
                vm.store.points.push(point);
            });
            $q.all(vm.store.points).then(function(data) {
                StoreService.updateStore(vm.store).then(function(data, error) {
                    toastr.success($translate.instant('store-management.messages.update.success'));
                }, function(data) {
                    toastr.error($translate.instant('store-management.messages.update.error'));
                });
            });
        }

        $rootScope.$on('$translateChangeSuccess', function() {
            getCategoryName();
        });

        function getCategoryName() {
            if (vm.store && vm.store.categoryKey ) {
                CategoryService.getCategoryTitleByKey(vm.store.categoryKey, $translate.use()).then(function(response) {
                    vm.store.categoryName = response.data;
                });
            }
        }

        function loadNumberOfFlyers() {
            FlyersService.getNumberOfFlyers(vm.store.id, $translate.use()).then(function(response) {
                vm.store.numberOfFlyers = response.data;
            });
        }

        function getColumnDefsUsers() {
            var testCellTemplate = '';
            return [{
                    field: 'login',
                    displayName: 'userManagement.login',
                    headerCellFilter: 'translate',
                    filtered: false
                },
                {
                    field: 'firstName',
                    displayName: 'userManagement.firstName',
                    headerCellFilter: 'translate',
                    filtered: false
                },
                {
                    field: 'lastName',
                    displayName: 'userManagement.lastName',
                    headerCellFilter: 'translate',
                    filtered: false
                },
                {
                    field: 'email',
                    displayName: 'userManagement.email',
                    headerCellFilter: 'translate',
                    width: '25%',
                    filtered: false
                },
                {
                    field: 'profileName',
                    displayName: 'userManagement.profileName',
                    headerCellFilter: 'translate',
                    cellTemplate: '<span ng-repeat=\"profile in row.entity.userProfiles\" class=\"label label-info\">{{ profile.profileName }}</span>'
                }
            ];
        }

        function getColumnDefsProfiles() {
            return [{
                field: 'name',
                displayName: 'showcaseApp.profile.name',
                headerCellFilter: 'translate'
            }];
        }

        function getColumnDefsTeams() {
            return [{
                field: 'name',
                displayName: 'showcaseApp.team.name',
                headerCellFilter: 'translate'
            }];
        }

        function getGridOptions() {
            return uiGridBuilder.withDefaultOptions()
                .withActionColumn(100)
                .addActionToActionColumn(
                    $translate.instant('store-management.tooltip.edit'), 
                    "vm.getEditPage", 
                    "fa fa-edit", 
                    "text-primary", 
                    true, 
                    "row.entity.disabledAction"
                )
                .addActionToActionColumn(
                    $translate.instant('store-management.tooltip.delete'), 
                    "vm.getDeletePage", 
                    "fa fa-trash", 
                    "text-primary", 
                    "grid.appScope.vm.isDeleteActionEnabled(row.entity)", 
                    "row.entity.disabledAction"
                )
                .withColumns(vm.columnDefs).build('search');
        }

        function refreshDataInfos() {
            vm.gridTableApi = vm.gridTableOptions.getApiGridTable();
            vm.startRegistry = (vm.gridTableApi.pagination.getPage() * vm.gridTableOptions.paginationPageSize) - vm.gridTableOptions.paginationPageSize;
            vm.endCalc = vm.gridTableOptions.paginationPageSize * vm.gridTableApi.pagination.getPage();
            vm.endRegistry = vm.endCalc > vm.totalItems ? vm.totalItems : vm.endCalc;
        }

        vm.addTeamStore = function() {
            $uibModal.open({
                templateUrl: 'app/admin/team-management/team-management-dialog.html',
                controller: 'TeamManagementDialogController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    team: function() {
                        return undefined;
                    },
                    storeId: function() {
                        return vm.store.id;
                    }
                }
            });
        }
        
        vm.addUserStore = function() {
            $uibModal.open({
                templateUrl: 'app/admin/user-management/user-management-dialog.html',
                controller: 'UserManagementDialogController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    user: function() {
                        return undefined;
                    },
                    storeId: function() {
                        return vm.store.id;
                    },
                    store: function() {
                        return angular.copy(vm.store);
                    },
                }
            });
        }

        vm.getEditPage = function(row) {
            if (vm.tabType === "Users") {
                vm.getEditPageUsers(row);
            } else if (vm.tabType === "Profiles") {
                vm.getEditPageProfiles(row);
            } else if (vm.tabType === "Teams") {
                vm.getEditPageTeam(row);
            }
        }

        vm.getDeletePage = function(row) {
            if (vm.tabType === "Users") {
                vm.getDeletePageUsers(row);
            } else if (vm.tabType === "Profiles") {
                vm.getDeletePageProfiles(row);
            } else if (vm.tabType === "Teams") {
                vm.getDeletePageTeam(row);
            }
        }

        vm.isDeleteActionEnabled = function(row) {
            if (vm.tabType === "Teams") {
                return row.type !== 'DEFAULT'
            } else {
                return true;
            }
        }

        vm.getEditPageUsers = function(row) {
            $uibModal.open({
                templateUrl: 'app/admin/user-management/user-management-dialog.html',
                controller: 'UserManagementDialogController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    storeId: function(){
                        return vm.store.id;
                    },
                    store: function() {
                        return angular.copy(vm.store);
                    },
                    user: function(UserService) {
                        return UserService.getUser({login:row.login, storeId: vm.store.id});
                    }
                }
            });
        }

        vm.getEditPageTeam = function(row) {
            $uibModal.open({
                templateUrl: 'app/admin/team-management/team-management-dialog.html',
                controller: 'TeamManagementDialogController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    storeId: function(){
                        return vm.store.id;
                    },
                    team: function() {
                        return row;
                    }
                }
            });
        }

        vm.getDeletePageTeam = function(team) {
            var remove = function() {
                TeamService.remove(team.id).then(function () {
                    toastr.success($translate.instant('team-management.deleted'));
                    $scope.$emit('userUpdated');
                });
            };
            ModalFactory.openWarningModal($translate.instant('team-management.delete-modal.title'),
                        $translate.instant('team-management.delete-modal.subtitle', { team: team.name }),
                        $translate.instant('team-management.delete-modal.small-text'),
                        null,
                        $translate.instant('team-management.delete-modal.confirm-button'),
                        $translate.instant('team-management.delete-modal.cancel-button'),
                        remove
                    );
        }

        vm.getDeletePageUsers = function(user) {
            var remove = function() {
                UserService.deleteUser({login: user.login, storeId: vm.store.id}).then(function (data) {
                    toastr.success($translate.instant('userManagement.deleted'));
                    $scope.$emit('userUpdated', data);
                });
            };
            ModalFactory.openWarningModal($translate.instant('userManagement.delete-modal.title'),
                        $translate.instant('userManagement.delete-modal.subtitle', { login: user.login }),
                        $translate.instant('userManagement.delete-modal.small-text'),
                        null,
                        $translate.instant('userManagement.delete-modal.confirm-button'),
                        $translate.instant('userManagement.delete-modal.cancel-button'),
                        remove
                    );
        }

        vm.getEditPageProfiles = function(row) {
            if(!row.disabledAction){
                $state.go('store-profile-detail', {
                    storeId: vm.store.id,
                    id: row.id
                });
            }
        }

        vm.getDeletePageProfiles = function(profile) {
            if(!profile.disabledAction){
                var remove = function() {
                    UserProfileService.deleteProfile(profile.id, vm.store.id).then(function(data) {
                        toastr.success($translate.instant('showcaseApp.profile.messages.remove.success'));
                        $scope.$emit('userUpdated', data);
                    }, function() {
                        toastr.error($translate.instant('showcaseApp.profile.messages.remove.error'));
                    });
                };
                ModalFactory.openWarningModal($translate.instant('showcaseApp.profile.delete-modal.title'),
                            $translate.instant('showcaseApp.profile.delete-modal.subtitle', { name: profile.name }),
                            $translate.instant('showcaseApp.profile.delete-modal.small-text'),
                            null,
                            $translate.instant('showcaseApp.profile.delete-modal.confirm-button'),
                            $translate.instant('showcaseApp.profile.delete-modal.cancel-button'),
                            remove
                        );
            }
        }

        vm.getEditStorePage = function(isStep) {
            $uibModal.open({
                templateUrl: 'app/admin/store-management/store-management-edit.html',
                controller: 'StoreManagementEditController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    store: function() {
                        return angular.copy(vm.store);
                    },
                    isStep: isStep
                }
            }).result.then(function(data) {
                if (data) {
                    vm.store = data;
                    loadNumberOfFlyers();
                }
            });
        }

        vm.getEditStoreImagePage = function() {

            $uibModal.open({
                templateUrl: 'app/admin/store-management/store-management-edit-image.html',
                controller: 'StoreManagementEditImageController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    store: function() {
                        return angular.copy(vm.store);
                    }
                }
            }).result.then(function(data) {
                vm.store.image = null;
                if (data) {
                    vm.store = data;
                    toastr.success($translate.instant('store-management.messages.update.success'));
                    $timeout(function() {
                        vm.store.image = vm.loadStoreImage(vm.store.storeLogo);
                    }, 4000);
                    loadNumberOfFlyers();
                }
            });
        }

        vm.changeTab = function(type) {
            if (type === "Users") {
                vm.columnDefs = getColumnDefsUsers();
                vm.usersTable = true;
                vm.profilesTable = false;
                vm.teamsTable = false;
            } else if (type === "Profiles") {
                vm.columnDefs = getColumnDefsProfiles();
                vm.usersTable = false;
                vm.profilesTable = true;
                vm.teamsTable = false;
            } else if (type === "Teams") {
                vm.columnDefs = getColumnDefsTeams();
                vm.usersTable = false;
                vm.profilesTable = false;
                vm.teamsTable = true;
            }
            vm.tabType = type;
            vm.gridTableOptions = getGridOptions();
        }

        vm.changeTableToProfile = function() {
            var params = null;
            var pageable = vm.gridTableOptions.getPageAndSortOptions().getPageAbleObj();
            pageable.size = pageable.size + 1;
            UserProfileService.listProfiles(pageable, $localStorage.defaultStore.storeId, vm.filterFields).then(function(response) {
                response.data = angular.forEach(response.data, function(value) {
                    return value.disabledAction = vm.tabType === "Profiles" ? (value.profileOwner === null && $localStorage.defaultStore.storeId !== 0 ? true: false) : false
                });
                vm.totalItems = response.headers['x-total-count'];
                vm.gridTableOptions.virtualizationThreshold = response.data.length;
                vm.gridTableOptions.update(response.data, vm.totalItems);
                vm.pagesSize = createArray(vm.totalItems, vm.tableDivisor);
                refreshDataInfos();
            });
        }

        vm.changeTableToTeam = function() {
            var pageable = vm.gridTableOptions.getPageAndSortOptions().getPageAbleObj();
            var params = {
                name: vm.filterFields && vm.filterFields.name && vm.filterFields.name.length > 0 ? vm.filterFields.name : null,
                storeId: $localStorage.defaultStore.storeId,
                isEnabled: true,
                size: pageable.size + 1,
                page: pageable.page
            }

            TeamService.list(params, pageable).then(function(response) {
                if (response.data) {
                    vm.totalItems = response.data.totalElements;
                    vm.gridTableOptions.virtualizationThreshold = response.data.totalElements;
                    vm.gridTableOptions.update(response.data.content, vm.totalItems);
                    vm.pagesSize = createArray(vm.totalItems, vm.tableDivisor);
                    refreshDataInfos();
                }
            });
        }

        vm.changePageSize = function(pageSize) {
            vm.gridTableOptions.paginationPageSize = pageSize;
            $scope.search();
        };


        vm.changeTableToUsers = function() {
            var pageable = vm.gridTableOptions.getPageAndSortOptions().getPageAbleObj();
            pageable.size = pageable.size + 1;
            UserService.listUsersByStoreId(pageable, vm.store.id, vm.filterFields).then(function(response) {
                vm.totalItems = response.headers['x-total-count'];
                vm.gridTableOptions.virtualizationThreshold = response.data.length;
                vm.gridTableOptions.update(response.data, vm.totalItems);
                vm.pagesSize = createArray(vm.totalItems, vm.tableDivisor);
                refreshDataInfos();
            });
        }

        vm.changeTableToTeams = function() {
            var pageable = vm.gridTableOptions.getPageAndSortOptions().getPageAbleObj();
            var params = {
                name: vm.filterFields && vm.filterFields.name && vm.filterFields.name.length > 0 ? vm.filterFields.name : null,
                storeId: $localStorage.defaultStore.storeId,
                isEnabled: true,
                size: pageable.size + 1,
                page: pageable.page
            }
            TeamService.list(params, pageable).then(function(response) {
                if (response.data) {
                    vm.totalItems = response.data.totalElements;
                    vm.gridTableOptions.virtualizationThreshold = response.data.totalElements;
                    vm.gridTableOptions.update(response.data.content, vm.totalItems);
                    vm.pagesSize = createArray(vm.totalItems, vm.tableDivisor);
                    refreshDataInfos();
                }
            });
        }

        function createArray(range, divisor) {
            if (!divisor) divisor = 0;
            if (divisor != 0) {
                range = range / divisor;
            }
            var values = [];
            var iteratorNumber = 0;
            for (var i = 0; i < range; i++) {
                iteratorNumber = divisor != 0 ? i * divisor : i + 1;
                values.push(iteratorNumber);
            }
            return values;
        };

        vm.changeColumnView = function($index) {
            vm.gridTableOptions.columnDefs[$index].visible = !(vm.gridTableOptions.columnDefs[$index].visible || vm.gridTableOptions.columnDefs[$index].visible === undefined);
            vm.gridTableApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
        };

        vm.changeColumnFilter = function($index) {
            if (vm.gridTableOptions.columnDefs[$index].filtered) {
                vm.gridTableOptions.columnDefs[$index].filtered = false;
                vm.gridTableOptions.columnDefs[$index].headerCellClass = '';
            } else {
                vm.gridTableOptions.columnDefs[$index].filtered = true;
                vm.gridTableOptions.columnDefs[$index].headerCellClass = 'ui-grid-bgcolor';
            }
            vm.gridTableApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
        };

        vm.checkColumnFilter = function(attr, $index) {
            if (attr.length >= 1 && !vm.gridTableOptions.columnDefs[$index].filtered) {
                vm.changeColumnFilter($index);
            }
            if (attr.length <= 0 && vm.gridTableOptions.columnDefs[$index].filtered) {
                vm.changeColumnFilter($index);
            }
            vm.reloadUsers();
        };
    }
})();
